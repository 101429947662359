<template>
  <b-container>
    <b-card>
      <div class="row justify-content-center align-items-center">
        <div class="col-xs-10 col-sm-10 col-md-6 col-lg-6">
          <div class="d-flex justify-content-center align-items-center">
            <feather-icon icon="CheckIcon" size="400" class="text-success" />
          </div>
          <div class="d-flex justify-content-center align-items-center mt-2">
            <h1>Processing your payment.</h1>
          </div>
          <div class="d-flex justify-content-center align-items-center mb-2">
            <h6>
              (Please wait up to 15-20 seconds for the payment to process
              successfully.)
            </h6>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <b-button
              variant="gradient-primary"
              class="box-shadow-1 w-50"
              href="/"
              >Back to Dashboard</b-button
            >
          </div>
          <div class="d-flex justify-content-center align-items-center mt-1">
            <b-button
              variant="gradient-primary"
              class="box-shadow-1 w-50"
              href="/bulk-order"
              >Continue Purchasing</b-button
            >
          </div>
        </div>
      </div>
    </b-card>
  </b-container>
</template>

<script>
export default {
  components: {},

  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/style.scss';
</style>
